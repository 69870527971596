import styled from "styled-components";

import * as V from "styles/variables";

export const DateTime = styled.time`
  color: var(--secondaryColor);
  display: block;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  margin-bottom: ${V.Space.sm};

  &:not(:last-child) {
    margin-top: ${V.Space.s};
    margin-bottom: ${V.Space.xxs};
  }
`;
