import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

import { getActiveTheme } from "utils/themes";

import * as S from "./styled";

const trackClick = (item) => {
  ReactGA.event({
    category: "Tag",
    action: "click",
    label: `Tag - ${item}`,
  });
};

const Tags = ({ tags, isLink }) => {
  return (
    <S.Tags>
      {/* <S.TagIcon /> */}
      {tags.map((tag, i) => (
        <S.TagHolder key={i}>
          {isLink ? (
            <Link to={`blog?query=` + tag}>
              <S.TagItem>{tag}</S.TagItem>
            </Link>
          ) : (
            <S.TagItem>{tag}</S.TagItem>
          )}
        </S.TagHolder>
      ))}
    </S.Tags>
  );
};

Tags.propTypes = {
  tags: PropTypes.node.isRequired,
  isLink: PropTypes.bool,
};

export default Tags;
